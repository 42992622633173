// Generated by Framer (1d71865)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Iw2RapIOL"];

const serializationHash = "framer-kFGQX"

const variantClassNames = {Iw2RapIOL: "framer-v-rjd4ig"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Iw2RapIOL", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-rjd4ig", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Iw2RapIOL"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-pz06xz"} data-framer-name={"stroke"} layout={"position"} layoutDependency={layoutDependency} layoutId={"w8FLCyvMn"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 21 28\"><path d=\"M 18.253 11.104 L 14.199 11.104 C 13.675 11.104 13.152 10.706 13.152 10.042 L 13.152 2.743 C 13.152 1.548 11.713 0.885 10.798 1.681 L 1.773 14.289 C 0.858 15.218 1.381 16.811 2.689 16.811 L 7.397 16.811 C 7.92 16.811 8.444 17.209 8.444 17.872 L 8.444 25.172 C 8.444 26.499 9.882 27.163 10.798 26.234 L 19.169 13.758 C 20.215 12.696 19.561 11.104 18.253 11.104 Z\" fill=\"transparent\" stroke-width=\"2\" stroke=\"var(--token-92563ce3-1dfb-430c-bd12-a26e5f9f3e1b, rgb(34, 17, 68)) /* {&quot;name&quot;:&quot;shadow planet&quot;} */\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>"} svgContentId={9903232871} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-kFGQX.framer-3h4dk9, .framer-kFGQX .framer-3h4dk9 { display: block; }", ".framer-kFGQX.framer-rjd4ig { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 36px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 36px; }", ".framer-kFGQX .framer-pz06xz { flex: none; height: 28px; position: relative; width: 21px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-kFGQX.framer-rjd4ig { gap: 0px; } .framer-kFGQX.framer-rjd4ig > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-kFGQX.framer-rjd4ig > :first-child { margin-left: 0px; } .framer-kFGQX.framer-rjd4ig > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 36
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerJIHZg5Oxe: React.ComponentType<Props> = withCSS(Component, css, "framer-kFGQX") as typeof Component;
export default FramerJIHZg5Oxe;

FramerJIHZg5Oxe.displayName = "Icon / Flash";

FramerJIHZg5Oxe.defaultProps = {height: 36, width: 36};

addFonts(FramerJIHZg5Oxe, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})